<template>
	<div class="goodsList">
		<!-- <div class="ad">
			<img class="regular" src="../../assets/sources/banner.png">
		</div> -->
		<div class="cate">{{$t('userTab.wish')}}</div>
		<div class="filter">
			<div class="main">
				<div class="options">
					
				</div>
				<!-- <div class="total">{{list.length}}{{$t('cart.unit')}}{{$t('cart.good')}}</div> -->
			</div>
		</div>
		<div class="list">
			<div class="item" v-for="val in list">
				<GoodsItem :detail="val" :isInLike="true" ></GoodsItem>
			</div>
		</div>
	</div>
</template>

<script>
import GoodsItem from '../../components/Goods/listItem.vue'
import Api from '../../api/request.js'
export default {
	name: 'Like',
	components: {
		GoodsItem
	},
	data() {
		return {
			page:{
				page: 1,
				size: 12
			},
			list:[],
			total: 0,
		};
	},
	created(){
		this.getList();
		this.$bus.$on('removeCollect',res=>{
			this.$dialog.confirm(this.$t('goodDetail.cancelCollect')+'?').then(confirm=>{
				this.remove(res);
			})
		})
	},
	methods:{
		getList(){
			// this.$loading.start();
			Api.Like.list({}).then(res=>{
				console.log(res)
				this.list = res.data.data;
				// this.$loading.done();
			})
		},
		remove(id){
			console.log(id)
			this.$dialog.loading();
			Api.Like.del({collect_id: id}).then(res=>{
				if(res.data.status == 'SUCCESSS'){
					this.getList();
				}
				this.$dialog.close();
			})
		}
	}
};
</script>

<style lang="less" scoped>
.ad{
	height: 540px;
}
.cate{
	font-size: 28px;
	font-weight: bold;
	line-height: 40px;
	margin: 30px 0;
	text-align: center;
}
.filter{
	padding: 0 7px;
	.main{
		height: 56px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid #757575;
		border-bottom: 1px solid #757575;
		.options{
			display: flex;
			align-items: center;
			.item{
				font-size: 14px;
				padding: 0 30px;
				cursor: pointer;
			}
			.devide{
				&:first-child{
					display: none;
				}
				&+.item{
					padding: 0 40px;
				}
				width: 1px;
				height: 30px;
				background: #BABABA;
			}
		}
	}
	.total{
		font-size: 14px;
		margin-right: 40px;
	}
}
.list{
	display: flex;
	flex-wrap: wrap;
	padding: 0 0 60px 7px;
	.item {
		width: calc((100vw - 32px) / 4);
		margin: 50px 6px 0 0;
	}
}
.more{
	height: 50px;
	line-height: 50px;
	text-align: center;
	border: 2px solid #BBB;
	margin: 0 21px 84px;
	font-size: 16px;
	cursor: pointer;
}
@media screen and (max-width: 500px) {
.ad{
	height: 28.1vw;
}
.cate{
	font-size: 4vw;
	font-weight: bold;
	margin: 4vw 0;
	text-align: center;
}
.filter{
	padding: 0 7px;
	.main{
		height: 12vw;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid #757575;
		border-bottom: 1px solid #757575;
		.options{
			width: 100%;
			display: flex;
			align-items: center;
			.item{
				font-size: 3vw;
				padding: 0 !important;
				flex: 1;
				text-align: center;
			}
			.devide{
				display: none;
			}
		}
	}
	.total{
		display: none;
		font-size: 14px;
		margin-right: 40px;
	}
}
.list{
	display: flex;
	flex-wrap: wrap;
	padding: 0 0 6vw 6px;
	.item {
		width: calc((100vw - 18px) / 2);
		margin: 6px 6px 0 0;
	}
}
.more{
	height: 12vw;
	line-height: 13vw;
	border: 1px solid #BBB;
	font-size: 4vw;
}
}
</style>
